const methods = {
    RECITER_FETCH_DATA: "RECITER_FETCH_DATA",
    RECITER_CANCEL_FETCHING: "RECITER_CANCEL_FETCHING",
    RECITER_CLEAR_DATA: "RECITER_CLEAR_DATA",
    RECITER_CHANGE_DATA: "RECITER_CHANGE_DATA",
    PUBMED_FETCH_DATA: "PUBMED_FETCH_DATA",
    PUBMED_CANCEL_FETCHING: "PUBMED_CANCEL_FETCHING",
    PUBMED_CLEAR_DATA: "PUBMED_CLEAR_DATA",
    PUBMED_CHANGE_DATA: "PUBMED_CHANGE_DATA",
    ADD_ERROR: "ADD_ERROR",
    CLEAR_ERROR: "CLEAR_ERROR",
    ACCEPT_PUBLICATION: "ACCEPT_PUBLICATION",
    REJECT_PUBLICATION: "REJECT_PUBLICATION",
    UNDO_PUBLICATION: "UNDO_PUBLICATION",
    USER_LOGIN: "USER_LOGIN",
    USER_LOGOUT: "USER_LOGOUT",
    IDENTITY_FETCH_DATA: "IDENTITY_FETCH_DATA",
    IDENTITY_CANCEL_FETCHING: "IDENTITY_CANCEL_FETCHING",
    IDENTITY_CHANGE_DATA: "IDENTITY_CHANGE_DATA",
    IDENTITY_CLEAR_DATA: "IDENTITY_CLEAR_DATA",
    IDENTITY_FETCH_ALL_DATA: "IDENTITY_FETCH_ALL_DATA",
    IDENTITY_CANCEL_ALL_FETCHING: "IDENTITY_CANCEL_ALL_FETCHING",
    IDENTITY_CHANGE_ALL_DATA: "IDENTITY_CHANGE_ALL_DATA",
    IDENTITY_CLEAR_ALL_DATA: "IDENTITY_CLEAR_ALL_DATA",
    GET_SESSION_ID: "GET_SESSION_ID"
}

export default methods